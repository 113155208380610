/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.btn svg {
  position: relative;
  top: -2px;
}

.modal-header.bg-info .close {
  color: white;
  text-shadow: 0 1px 0 black;
}

.modal-header.bg-danger .close {
  color: white;
  text-shadow: 0 1px 0 black;
}

input.password[type=text]:-webkit-autofill {
  -webkit-text-security: disc;
}
